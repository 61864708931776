











import { Modal } from '@/app_code/Modals/Modal'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class InvoiceDetails extends Vue {
  public invoice: InvoiceModel | null = null
  public invoiceUrl: string | null = null

  async mounted () {
    const invoiceId = this.$route.params.id as string

    this.invoice = await Services.API.Payment.GetInvoice(invoiceId)

    const invoiceHtml = await Services.API.Payment.GetInvoiceHTML(invoiceId)

    this.invoiceUrl = URL.createObjectURL(invoiceHtml)
  }

  beforeDestroy () {
    if (this.invoiceUrl) URL.revokeObjectURL(this.invoiceUrl)
  }

  async downloadInvoice (): Promise<void> {
    if (!this.invoice) return

    const modal = new Modal({ Heading: 'Please Wait...', HtmlBody: 'Generating Invoice PDF...' })
    modal.Show()

    const pdfBlob = await Services.API.Payment.GetInvoicePDF(this.invoice.InvoiceId)

    const pdfUrl = URL.createObjectURL(pdfBlob)

    modal.Hide()

    window.open(pdfUrl, '_blank')

    setTimeout(() => {
      URL.revokeObjectURL(pdfUrl)
    }, 5000)
  }
}
